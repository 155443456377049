// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()

require("jquery")
require("jquery-scrollspy");
require("fancy-box");

var Swiper = require('swiper');

$.ajaxSetup({ cache: true });

$(document).on('turbolinks:load ajax:paginate', function() {
    $('.swiper-container').each(function () {
        var swiperThumbs = $(this).parent().find('.swiper-thumbs img, .swiper-selectors div.swiper-photo-selector');
        var swiperThumbsOuter = $(this).parent().find('.swiper-thumbs, .swiper-selectors');

        var swiper = new Swiper (this, {
                          preloadImages: false,
                          lazy: {
                            loadPrevNext: true,
                            loadOnTransitionStart: true
                          },
                          on: {
                            slideChange: function() {
                              swiperThumbs.removeClass('selected');
                              var $active = swiperThumbs.eq(this.activeIndex);
                              $active.addClass('selected');
                            }
                          }
                        });

        if (swiperThumbs.length > 0) {
          swiperThumbs.click(function(){
            var index = $(this).data('index');
            swiper.slideTo(index);
          });
        }
    });
});

$(document).on('turbolinks:load', function(){
  $('[data-fancybox="watch-photos"]').fancybox({
    buttons: [
      "zoom",
      "fullScreen",
      "close"
    ],
    hash: false,
    infobar: false
  });

  $(document).click(function(e){
    if(!$(e.target).hasClass('dropdown-label')) {
      $('.dropdown').hide();
    }
  })

  $('.dropdown-label').click(function(){
    $(this).parent().find('.dropdown').toggle();
  });

  var photosHeight = $('.watch-photos-col').height() - 450;

  $(window).scroll(function(){
    var st = $(this).scrollTop();
    var scrollBarFraction = st / photosHeight;
    var scrollBarHeight = scrollBarFraction * 474;
    $('.watch-scroll-progress').height(scrollBarHeight);
  });

  var showToggle = false;

  $('.watch-details-toggle').click(function(){
    $('.watch-main-details-content').slideToggle();

    showToggle = !showToggle;

    $('.watch-main-details-status').html(showToggle ? '-' : '+');
  });

  var mih = $('.watch-main-info').height();
  $('.watch-inquire-button').css('top', (mih + 70) + 'px');

  $('.watch-info-col').scrollspy({
    min: 50,
    max: photosHeight,
    onEnter: function(element, position) {
        $('.watch-info-col').addClass('fixed');
    },
    onLeave: function(element, position) {
        $('.watch-info-col').removeClass('fixed');
    }
  });

  $('.watch-scroll-col').scrollspy({
    min: 50,
    max: photosHeight,
    onEnter: function(element, position) {
        $('.watch-scroll-col').addClass('fixed');
    },
    onLeave: function(element, position) {
        $('.watch-scroll-col').removeClass('fixed');
    }
  });
});

$(document).on('turbolinks:load', function(){
  $('.nav-search-icon').click(function(){
    $('.nav-search-form').fadeIn();
    $('.nav-search-icon').fadeOut();
    $('#search-form input').focus();
  });

  $('#search-form input').blur(function(){
    $('.nav-search-form').fadeOut();
    $('.nav-search-icon').fadeIn();
  });
});  

window.scroll_loading = false;

$(window).on('scroll touchmove', function(){ 
  if (typeof window.next_page_url == 'undefined') {
    return;
  }

  if (window.next_page_url    
      && !window.scroll_loading    
      && $(window).scrollTop() > $(document).height() - $(window).height() - 1000) {   

    window.scroll_loading = true;  
    var currentUrl = window.next_page_url;

    $('#scroll-loading').show();

    $.getScript(currentUrl, function(){
      window.scroll_loading = false;
      $('#scroll-loading').hide();
    });   
  }
});